import { darken } from '@mui/material/styles'

const gsx = {
  leftContainer: {
    position: '-webkit-sticky',
    top: 20,
    bottom: 20,
    zIndex: 5,
    paddingY: { xs: 2, md: 4 },
  },
  listContainer: {
    paddingTop: 4,
    px: { xs: 4, sm: 3 },
    lineHeight: '22px !important',
  },
  rightContainerItems: {
    py: 3,
    '> *': {
      px: 3,
    },
    backgroundColor: '#fff',
  },
  buttonLink: {
    color: '#1F1F1F',
    fontSize: 11,
    lineHeight: '16px',
    marginLeft: -2,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  textLink: {
    fontSize: '12px !important',
    color: 'text.grey300',
    lineHeight: '14px !important',
    marginLeft: -1,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  scrollableSection: {
    overflowY: {
      sm: 'auto',
    },
    overflowX: 'hidden',
    height: {
      xs: 'auto',
      md: '700px',
    },
    '&::-webkit-scrollbar:vertical': {
      width: 12,
      backgroundColor: '#7d7d7d',
      borderradius: 20,
    },
  },
  rightContainer: {
    backgroundColor: 'background.grey',
  },
  rootTabs: {
    backgroundColor: 'background.grey',
    paddingRight: 1,
    paddingLeft: 1,
    paddingBottom: 4,
    '& h2': {
      textAlign: 'center',
    },
  },

  darkTabs: {
    backgroundColor: 'secondary.main',
    color: 'text.white',
    '& h2': {
      textAlign: 'left',
    },
    '& .itemContainer': {
      width: '100%',
      overflow: 'hidden',
      height: '128px !important',
      maxHeight: '128px !important',
      maxWidth: '100% !important',
    },
    '& .contentText': {
      marginBottom: 14,
    },
  },

  gradient: {
    background: 'linear-gradient(197.77deg, #20B0E7 -100.62%, rgba(32, 193, 231, 0.76) -47.89%, #122549 67.17%)!important',
  },

  whiteButtonTab: {
    backgroundColor: 'background.default',
    border: '1px solid #E9E9E9',
    color: 'common.blueDark',
  },

  h1: {
    lineHeight: '30px !important',
  },

  h4: {
    fontSize: 14,
    lineHeight: '16px !important',
    color: 'text.primaryDark',
  },

  banner: {
    justifyContent: 'center',
    '& .swiper-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    marginTop: {
      xs: 1,
      sm: 2,
    },
    marginBottom: {
      xs: 1,
      sm: 2,
    },
  },

  mainButton: {
    width: '100%',
    padding: 1.5,
    fontSize: 12,
    lineHeight: '18px !important',
    textTransform: 'uppercase!important',
    backgroundColor: 'highlight.main',
    color: 'secondary.contrastText',
    marginBottom: 0,
    '&:hover': {
      backgroundColor: darken('#E3007D', 0.1),
    },

    '&.featured': {
      backgroundColor: '#8C46ED',
    },
    margin: 'auto',
    display: 'block',
    textAlign: 'center',
  },

  terms: {
    fontSize: '10px !important',
    lineHeight: '15px !important',
    marginTop: 1,
    color: 'text.grey300',
    textAlign: 'center',
  },

  pinkButton: {
    textTransform: 'none!important',
    margin: 'auto',
    width: { xs: '100%', md: 348 },
    py: 1.5,
    backgroundColor: 'highlight.main',
    color: 'contrastText',
    marginBottom: 0,
    fontWeight: 600,
    lineHeight: '18px',
    '&:hover': {
      backgroundColor: darken('#E3007D', 0.1),
    },
    '&.featured': {
      backgroundColor: '#8C46ED',
    },
    '&.Mui-disabled': {
      color: 'text.primaryDark',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
    },
  },

  whiteButton: {
    py: 1.2,
    width: 160,
    // fontSize: 12,
    maxWidth: { sm: 256 },
    fontWeight: 500,
    borderRadius: 32,
    textAlign: 'center',
    display: 'flex',
    color: 'text.primaryDark',
    border: '1px solid #E9E9E9',
    lineHeight: '18px !important',
    backgroundColor: 'background.default',
  },

  appraisalContent: {
    px: { xs: 2, md: 0 },
  },

  appraisalInput: {
    '& .MuiInputBase-root': {
      mb: 0,
    },

    label: {
      color: '#000',
      fontWeight: 'normal !important',
      fontSize: '12px !important',
      lineHeight: '14px !important',
    },

    div: {
      boxShadow: 'none !important',
      border: '1px solid #e9e9e9',
      marginBottom: 0,
    },

    input: {
      py: 1.2,
      color: '#666666',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '16px !important',
    },
    '& input::placeholder': {
      color: 'inherit',
      fontWeight: 'normal',
      lineHeight: '18px !important',
    },
  },

  appraisalTitle: {
    my: 2,
    fontWeight: 'bold !important',
    fontSize: '20px !important',
    lineHeight: '22px !important',
    color: 'text.primaryDark',
  },

  vtaBanner: {
    paddingX: 2,
    marginTop: 4,
    marginBottom: '110px!important',
  },

  googleReviewsContainer: {
    paddingX: { xs: 3, lg: 7 },
    paddingY: { xs: 3, lg: 8 },
    borderRadius: 0.5,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },

  submitButtonCheckoutDigital: {
    width: '96px',
    height: '40px',
    background: 'rgba(32, 193, 231, 0.05)',
    border: '1px solid #20C1E7',
    borderRadius: '32px',
    color: '#343434',
  },
  submitButtonCheckoutDigitalDisabled: {
    width: '96px',
    height: '40px',
    background: 'rgba(32, 193, 231, 0.05)',
    border: '1px solid #D0D0D0',
    borderRadius: '32px',
    color: '#fff !important',
  },
}

export default gsx
