import React, { memo, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ClearIcon from '@mui/icons-material/Clear'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import { FormattedMessage, useIntl } from 'react-intl'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PhoneIcon from 'components/icons/Icono_phone.svg'
import Sticky from 'react-sticky-el'
import EventEmitter from 'services/EventEmitter'
import useUtms from 'hooks/useUtms'
import usePageProps from 'hooks/usePageProps'
import WhatsAppIcon from 'components/icons/whatsapp_icon.svg'
import ModalCancelSteps from 'apps/AppraisalForm/Modals/ModalCancelSteps'
import OffersCancellationModal from 'apps/AppraisalForm/Modals/OffersCancellationModal'
import { useModal } from 'components/shared/Modal'
import CurrentLogo from 'public/img/logo.png'
import { signOut, useSession } from 'next-auth/react'
import Logout from '@mui/icons-material/Logout'
import { isSellYourCarSteps } from './constants'
import sx from './styles'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const Header = (props) => {
  const {
    logo = '',
    logoAltText = '',
    mainMenu = [],
    activeIndex = -1,
    position = 'relative',
    hideMenu = false,
    showBackButton = true,
    whatsappMessage = '',
    breadcrumbs = [],
    isAppraisalForm,
    variantWhatsappPhone = 'sales-your-car',
    isPhoneCTA = true,
    isDisabledSticky = false,
    isEasyParticularVdp = false,
  } = props
  const router = useRouter()
  const query = useUtms()
  const { status } = useSession()
  const { handleOpenModal, handleCloseModal } = useModal()
  const { formatMessage: f } = useIntl()
  const {
    whatsapp,
    whatsappVTA,
    whatsQueretaro,
    params,
    salesPhone,
    shoppingPhone,
    homePhoneNumber,
    auctionPhone,
    phoneQueretaro,
    phoneQueretaroVDP,
  } = usePageProps()
  const [inverted, setInverted] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [headerStyles, setHeaderStyles] = useState(sx.appbar)
  const showMobileMenuIcon = isSellYourCarSteps(router.asPath)
  const phoneNumber = router.asPath === '/'
    ? homePhoneNumber
    : router.asPath.includes('/subastas')
      ? auctionPhone
      : (variantWhatsappPhone === 'clikauto'
        ? shoppingPhone
        : variantWhatsappPhone === 'queretaro'
          ? phoneQueretaro
          : variantWhatsappPhone === 'queretaroVDP'
            ? phoneQueretaroVDP
            : salesPhone
      )
  const whatsappWelcomeMessage = f({ id: whatsappMessage?.id || 'HOME_WHATSAPP_MESSAGE' }, whatsappMessage?.values)
  const whatsappNumber = variantWhatsappPhone === 'clikauto' ? whatsapp : variantWhatsappPhone === 'queretaro' ? whatsQueretaro : whatsappVTA
  const initialStep = f({ id: 'ROUTE_QUOTE_CAR_STEP_YEAR' })
  const initialLink = f({ id: 'ROUTE_QUOTE_CAR' }, { step: initialStep })
  const toggleInverted = (val) => setInverted(val)
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setMobileMenuOpen((prev) => (open === undefined ? !prev : open))
  }
  const goBack = () => router.back()
  const closeModal = () => handleCloseModal()
  const handleSubmit = () => {
    closeModal()
    router.push(f({ id: 'ROUTE_SELL_YOUR_CAR' }))
  }
  const handleResetSteps = () => {
    closeModal()
    router.push(initialLink)
  }
  const toggleOffersCancellationModal = () => {
    handleOpenModal({
      title: f({ id: 'APPRAISAL_OFFER_CANCELLATION_MODAL_TITLE' }),
      body: (
        <>
          <OffersCancellationModal
            onClose={closeModal}
            onReset={handleResetSteps}
            onSubmit={handleSubmit}
          />
        </>
      ),
      configProps: {
        isDividerHeader: true,
        showClose: false,
        scroll: 'body',
      },
      isDismissible: false,
    })
  }

  useEffect(() => {
    if (isAppraisalForm) {
      setHeaderStyles((prev) => ({
        ...prev,
        ...sx.isAppraisalForm,
      }))
    }

    if (isEasyParticularVdp) {
      setHeaderStyles((prev) => ({
        ...prev,
        ...sx.vdp,
      }))
    }

    if (!isAppraisalForm && !isEasyParticularVdp) {
      setHeaderStyles(sx.appbar)
    }

    return () => setHeaderStyles(sx.appbar)
  }, [isAppraisalForm, isEasyParticularVdp])

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [router.pathname])

  const toggleStepsCancellationModal = () => {
    handleOpenModal({
      title: f({ id: 'APPRAISAL_CANCEL_PROCESS_TITLE' }),
      body: (
        <ModalCancelSteps
          onClose={closeModal}
          onSubmit={handleSubmit}
        />
      ),
      configProps: {
        showClose: false,
        scroll: 'body',
        isDividerHeader: true,
      },
      isDismissible: false,
    })
  }

  const handleRouter = () => {
    EventEmitter.emit('logo-click')
    if (!isAppraisalForm) {
      router.push(`/${query}`)
      return
    }
    if (params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_YEAR' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_DATA' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_MAKE' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_MODEL' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_VERSION' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_COLOR' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_KILOMETERS' })
      || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_INFORMATION' })) {
      toggleStepsCancellationModal()
    } else if (params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_INSPECTION_VARIANT' })) {
      toggleOffersCancellationModal()
    } else if (params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_EXPEDIENTE' })) {
      // eslint-disable-next-line no-useless-return
      return
    } else {
      router.push(`/${query}`)
    }
  }
  const isStepYear = () => params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_YEAR' })

  const menu = (
    <>
      <Box sx={sx.menu}>
        {mainMenu.filter((item) => (!item.hiddenHeader && !item.hideInHeader)).map(
          (item, index) => {
            const className = clsx({
              selected: index === activeIndex,
            })

            return (
              <Link
                key={item.id}
                href={`${item.url}${query}`}
                passHref
                prefetch={!item.skipPrefetch}
              >
                <Button
                  key={item.id}
                  sx={sx.menuItem}
                  className={className}
                  href={`${item.url}${query}`}
                  id={item.link_id}
                  title={item.alt}
                  size="small"
                >
                  {item.title}
                </Button>
              </Link>
            )
          },
        )}
        {/* {status === 'unauthenticated' && (
          <Link
            href={f({ id: 'ROUTE_AUCTIONS' })}
            passHref
            prefetch={false}
          >
            <Button
              sx={sx.menuItem}
              href={`${f({ id: 'ROUTE_AUCTIONS' })}${query}`}
              className={clsx({
                selected: router.asPath.includes(f({ id: 'ROUTE_AUCTIONS' })),
              })}
              id="subastas-landing"
              title="subastas"
              size="small"
            >
              {f({ id: 'AUCTION' })}
            </Button>
          </Link>
        )} */}
        {status === 'authenticated' && (
          <>
            <Link
              href={f({ id: 'ROUTE_AUCTIONS_SEARCH' })}
              passHref
              prefetch={false}
            >
              <Button
                sx={sx.menuItem}
                href={`${f({ id: 'ROUTE_AUCTIONS_SEARCH' })}${query}`}
                className={clsx({
                  selected: router.asPath.includes(f({ id: 'ROUTE_AUCTIONS' })),
                })}
                id="subastas-link"
                title="subastas"
                size="small"
              >
                {f({ id: 'AUCTION' })}
              </Button>
            </Link>
            <Box
              component="a"
            >
              <Button
                title="logout"
                size="small"
                onClick={() => signOut({ callbackUrl: 'https://clikauto.com/subastas' })}
                sx={sx.menuItem}
              >
                <Logout />
                Salir
              </Button>
            </Box>
          </>
        )}
      </Box>

    </>
  )

  const mobileMenuIcon = (
    <Box sx={sx.mobileMenu}>
      <IconButton
        size="small"
        sx={sx.whatsappTag}
        component="a"
        disableRipple
        // href={`//wa.me/${whatsappNumber}?text=${whatsappWelcomeMessage}`}
        href={isPhoneCTA ? `tel:${phoneNumber}` : `//wa.me/${whatsappNumber}?text=${whatsappWelcomeMessage}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={f({ id: 'SEND_US_A_WHATSAPP' })}
      >
        {isPhoneCTA ? <PhoneIcon /> : <WhatsAppIcon />}
      </IconButton>
    </Box>
  )

  const mobileMenu = (
    <Box sx={sx.mobileMenu}>
      <>
        {!(isAppraisalForm || isEasyParticularVdp) && showMobileMenuIcon && (
          mobileMenuIcon
        )}
        {!hideMenu && (
          <>
            <IconButton
              sx={sx.mobileMenuIcon}
              onClick={toggleDrawer()}
              aria-label={f({ id: mobileMenuOpen ? 'HIDE_MOBILE_MENU' : 'SHOW_MOBILE_MENU' })}
            >
              <MenuIcon sx={sx.blueIcon} />
            </IconButton>

            <SwipeableDrawer
              anchor="right"
              open={mobileMenuOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              sx={sx.drawer}
            >
              <DrawerHeader sx={sx.drawerHeader}>
                <Box
                  sx={{
                    cursor: 'pointer',
                    display: 'block',
                    width: { lg: 158, xs: 120 },
                    height: { xs: 21, lg: 28 },
                  }}
                  onClick={handleRouter}
                >
                  <Image
                    src={CurrentLogo}
                    alt={logoAltText}
                    width={200}
                    height={36}
                    layout="responsive"
                    priority
                  />
                </Box>
                <Box>

                  {!isEasyParticularVdp && (
                    <IconButton
                      size="small"
                      sx={sx.whatsappTag}
                      component="a"
                      disableRipple
                      // href={`//wa.me/${whatsappNumber}?text=${whatsappWelcomeMessage}`}
                      href={isPhoneCTA ? `tel:${phoneNumber}` : `//wa.me/${whatsappNumber}?text=${whatsappWelcomeMessage}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Whatsapp"
                    >
                      {isPhoneCTA ? <PhoneIcon /> : <WhatsAppIcon />}
                    </IconButton>
                  )}

                  <IconButton
                    onClick={toggleDrawer(false)}
                    aria-label={f({ id: 'CLOSE' })}
                  >
                    <ClearIcon sx={{ ...sx.blueIcon, ...sx.clearIcon }} />
                  </IconButton>
                </Box>
              </DrawerHeader>
              <List>
                {mainMenu.filter((item) => (!item.hiddenHeader && !item.hideInHeader))
                  .map((item, index) => (
                    <Link
                      key={item.id}
                      href={`${item.url}${query}`}
                      passHref
                    >
                      <ListItem
                        sx={sx.mobileMenuItem}
                        className={clsx({
                          selected: index === activeIndex,
                        })}
                        component="a"
                        onClick={toggleDrawer(false)}
                        divider
                      >
                        <ListItemText primary={item.title} />
                      </ListItem>
                    </Link>
                  ))}
                {status === 'authenticated' && (
                  <>
                    <Link
                      key="subastas"
                      href={f({ id: 'ROUTE_AUCTIONS_SEARCH' })}
                      passHref
                    >
                      <ListItem
                        sx={sx.mobileMenuItem}
                        className={clsx({
                          selected: router.asPath.includes(f({ id: 'AUCTION' })),
                        })}
                        component="a"
                        onClick={toggleDrawer(false)}
                        divider
                      >
                        <ListItemText primary={f({ id: 'AUCTION' })} />
                      </ListItem>
                    </Link>

                    <ListItem
                      title="logout"
                      size="small"
                      onClick={() => signOut({ callbackUrl: 'https://clikauto.com/subastas' })}
                      sx={sx.mobileMenuItem}
                      component="a"
                    >
                      <IconButton
                        size="small"
                        sx={{ ml: 2, margin: { xs: 'auto', lg: 'unset' } }}
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <Typography>
                          Salir
                        </Typography>
                      </IconButton>
                    </ListItem>
                  </>
                )}
              </List>
            </SwipeableDrawer>
          </>
        )}
      </>
    </Box>
  )

  const srpPath = router.asPath.includes('autos-seminuevos')
  const schedulingPath = router.asPath.includes('programar-inspeccion-easy')
  const cionfirmationPath = router.asPath.includes('venta-directa-confirmacion') || router.asPath.includes('easy-confirmacion')

  return (
    <Sticky
      onFixedToggle={toggleInverted}
      topOffset={inverted ? 4 : 0}
      className="wrapper-stycky-header"
      disabled
    >
      <Box
        sx={sx.navbar}
        className={clsx({
          hideLogo: cionfirmationPath,
        })}
      >
        <AppBar
          position={position}
          elevation={inverted && !srpPath ? 2 : 0}
          sx={headerStyles}
          className={clsx({
            scrolled: inverted,
            noSticky: isDisabledSticky,
          })}
        >
          <Toolbar disableGutters>
            {showBackButton && !(isAppraisalForm || isEasyParticularVdp) && (
              <IconButton
                color="primary"
                onClick={goBack}
                sx={{ display: { xs: 'block', md: 'none' } }}
                aria-label={f({ id: 'BACK_BUTTON' })}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}

            <Box sx={sx.logoContainer}>

              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'block',
                  width: { lg: 158, xs: 120 },
                }}
                onClick={handleRouter}
              >
                <Box
                  sx={sx.logo}
                  className={clsx({
                    hideLogo: schedulingPath,
                  })}
                >
                  {typeof logo === 'string' ? (
                    <Image
                      src={CurrentLogo}
                      alt={logoAltText}
                      width={200}
                      height={36}
                      layout="responsive"
                      priority
                    />
                  ) : (
                    <CurrentLogo />
                  )}
                </Box>
              </Box>

              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumbs
                  sx={sx.breadcrumbs}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs.map((item, i) => (
                    <Link
                      key={i}
                      href={f({ id: item.href.id }, item.href.values)}
                      passHref
                    >
                      <MuiLink color="inherit">
                        <FormattedMessage
                          id={item.label.id}
                          values={item.label.values}
                        />
                      </MuiLink>
                    </Link>
                  ))}
                </Breadcrumbs>
              )}
            </Box>
            {!hideMenu && (
              menu
            )}
            {(isAppraisalForm && isStepYear()) && (
              <Button
                sx={sx.leaveButton}
                onClick={toggleStepsCancellationModal}
              >
                <FormattedMessage id="LEAVE" />
              </Button>
            )}
            {mobileMenu}
          </Toolbar>
        </AppBar>
      </Box>
    </Sticky>
  )
}

export default memo(Header)
