import React, { memo } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import sx from './styles'

const OffersCancellationModal = (props) => {
  const {
    onClose: onCloseModal, onSubmit, onReset,
  } = props

  return (
    <>
      <Typography
        variant="body1"
        sx={sx.description}
      >
        <FormattedMessage id="APPRAISAL_OFFER_CANCELLATION_MODAL_DESCRIPTION" />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        id="button-vta-continuarventa"
        onClick={onCloseModal}
        sx={sx.button}
      >
        <FormattedMessage
          id="APPRAISAL_OFFER_CANCELLATION_MODAL_BUTTON_1"
        />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={sx.button}
        onClick={onReset}
        id="button-vta-otracotizacion"
      >
        <FormattedMessage
          id="APPRAISAL_OFFER_CANCELLATION_MODAL_BUTTON_2"
        />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={sx.button}
        onClick={onSubmit}
        id="btn-vta-preciobajo"
      >
        <FormattedMessage
          id="APPRAISAL_OFFER_CANCELLATION_MODAL_BUTTON_3"
        />
      </Button>
    </>

  )
}

export default memo(OffersCancellationModal)
