const styles = {
  title: {
    textAlign: 'center',
    marginTop: 2,
  },

  description: {
    textAlign: 'center',
    marginY: 2,
  },

  button: {
    width: '100%',
    maxWidth: '100%',
    mb: 2,
  },
}
export default styles
