import { useRouter } from 'next/router'
import size from 'lodash/size'
import { toQueryString } from 'utils/universal'

const allowedUtms = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]

const filterValidUtms = (utms) => {
  return Object.keys(utms)
    .filter((k) => allowedUtms.includes(k))
    .reduce((o, k) => {
      o[k] = utms[k]
      return o
    }, {})
}

const utm = (query) => {
  const sep = '&'
  const utmRegx = new RegExp(`((utm_[^=]+)=([^\\${sep}]+))`, 'gi')
  const matched = query.match(utmRegx) || []
  return (matched.reduce((o, u) => {
    const kV = u.split('=')
    // eslint-disable-next-line prefer-destructuring
    o[kV[0]] = kV[1]
    return o
  }, {})) || {}
}

const useUtms = () => {
  const router = useRouter()
  const query = router.asPath.split('?')?.[1] ?? ''
  if (query.length === 0) return ''
  const utms = filterValidUtms(utm(query))
  return size(utms) ? `?${toQueryString(utms)}` : ''
}

export default useUtms
