const styles = {
  description: {
    textAlign: 'center',
    mb: 2,
    mt: 2,
  },

  button: {
    width: '100%',
    maxWidth: '100%',
    mb: 2,
  },

}
export default styles
