import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useIntl } from 'react-intl'
import usePageProps from 'hooks/usePageProps'
import Header from 'components/layout/Header'
import Box from '@mui/material/Box'
import isEmpty from 'lodash/isEmpty'
import { getRobotsContentSRP } from 'utils/vehicles'
import { useSelector } from 'react-redux'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { SRP_TYPE } from 'enums'
import { useRouter } from 'next/router'
import clsx from 'clsx'

const SocialMediaSeoTagsDynamic = dynamic(() => import('components/shared/SocialMediaSeoTags'))
const JsonLDTagDynamic = dynamic(() => import('components/seo/JsonLDTag'))
const AppraisalFooterDynamic = dynamic(() => import('apps/AppraisalForm/AppraisalFooter'))
const FooterDynamic = dynamic(() => import('components/layout/Footer'))
const AuctionFooterDynamic = dynamic(() => import('components/Auction/AuctionFooter'))

const Page = ({
  children, variant = '', isMainSteps = false, sx: sxProp,
}) => {
  const { formatMessage: f } = useIntl()
  const {
    siteName,
    titleMeta,
    titleMetaComplement = '',
    defaultTitleMetaComplement = false,
    activeTitleMetaComplement = false,
    descriptionMeta,
    headerProps: headerPropsProp = {},
    footerProps: footerPropsProp = {},
    hideMenu,
    hideFooter,
    hideHeader = false,
    makes,
    mainMenu,
    footerMenu,
    footerAuctionMenu,
    params,
    socialMediaTags = {},
    jsonLDData = {},
    variantWhatsappPhone = 'sales-your-car',
    variantPhone = 'sales',
    robotsContent: robotsContentProp = 'index, follow',
    srpType = SRP_TYPE.NORMAL,
    isDraftEasy = false,
  } = usePageProps()
  const { filters } = useSelector((s) => s.search)
  const router = useRouter()
  const { isAppraisalForm } = useMemo(() => headerPropsProp, [headerPropsProp])
  const isInspectionSection = useMemo(() => params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_INSPECTION' }) || f({ id: 'ROUTE_QUOTE_CAR_STEP_AT_HOME_INSPECTION' }) || f({ id: 'ROUTE_QUOTE_CAR_STEP_SUCURSAL_INSPECTION' }), [params?.step])
  const isIndexedPage = process.env.NEXT_PUBLIC_APP_ENV === 'production' && srpType === SRP_TYPE.NORMAL && !isDraftEasy
  const [robotsContent, setRobotsContent] = useState(robotsContentProp)
  // const [showRobotsTag, setShowRobotsTag] = useState(showRobotsTagProp)
  let titleMetaComplementFormatted

  if (activeTitleMetaComplement) {
    titleMetaComplementFormatted = defaultTitleMetaComplement
      ? siteName
      : f({ id: titleMetaComplement })
  }

  const headerProps = useMemo(() => {
    const props = {
      mainMenu,
      position: 'relative',
      logo: '/img/logo.png',
      invertedLogo: '/img/logoInverted.png',
      logoAltText: siteName,
      hideMenu,
      variantWhatsappPhone,
      ...headerPropsProp,
    }
    return props
  }, [mainMenu, siteName, hideMenu, variantWhatsappPhone, headerPropsProp])

  const menu = router.asPath.includes('subastas') ? footerAuctionMenu : footerMenu

  const footerProps = useMemo(() => {
    const props = {
      logo: '/img/logoInverted.png',
      makes,
      mainMenu,
      menu,
      isMainSteps,
      variantPhone,
      footerMenu,
      logoAltText: siteName,
      ...footerPropsProp,
    }
    return props
  }, [makes, mainMenu, footerMenu, footerAuctionMenu, isMainSteps, variantPhone,
    footerPropsProp, menu])

  const addClass = useCallback(() => {
    if (typeof window === 'object') {
      document.body.classList.add('bg-grey')
    }
  }, [])

  const removeClass = useCallback(() => {
    if (typeof window === 'object') {
      document.body.classList.remove('bg-grey')
    }
  }, [])

  useEffect(() => {
    if (variant === 'bg-grey') addClass()
    else removeClass()
  }, [])

  const removeBottomPaddingContainer = useCallback(
    () => params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_YEAR' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_MAKE' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_MODEL' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_VERSION' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_COLOR' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_KILOMETERS' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_INFORMATION' })
      && params?.step !== f({ id: 'ROUTE_QUOTE_CAR_STEP_INFORMATION' }),
    [params?.step],
  )

  const ValidationMargin = router.asPath.includes('/subastas') || router.asPath.includes('/vende-tu-auto-cotizador')

  const isDigitalExpedientPath = (
    params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_ID_FRONT' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_ID_BACK_EASY' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_ID_BACK' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_ID_BACK_EASY' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_CIRCULATION_CARD_FRONT' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_CIRCULATION_CARD_BACK' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_FRONT' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_BACK' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_FRONT_EASY' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_BACK_EASY' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_FRONT_EASY_2' })
    || params?.step === f({ id: 'ROUTE_DIG_EXPEDIENT_STEP_INVOICE_BACK_EASY_2' })
  )

  const isSchedulePath = (
    params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_INSPECTION_VARIANT' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_INSPECTION_EASY_VARIANT' })
  )

  const hideHeaderOnVTAFlow = (
    params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_INSPECTION_VARIANT' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_INSPECTION_EASY_VARIANT' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_CONFIRMATION_EASY' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_SCHEDULE_CONFIRMATION_DIRECT' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_OFFERS_DIRECT_SALLING' })
    || params?.step === f({ id: 'ROUTE_QUOTE_CAR_STEP_OFFERS_EASY' })
  )

  useEffect(() => {
    const container = document.querySelector('#main-content-container')
    if (removeBottomPaddingContainer()) {
      container.classList.add('pb-0')
    } else {
      container.classList.remove('pb-0')
    }
    if (isDigitalExpedientPath) {
      container.classList.add('dig-exp')
    }
  }, [params])

  useNonInitialEffect(() => {
    setRobotsContent(getRobotsContentSRP(filters, isDraftEasy))
  }, [filters, isDraftEasy])

  const mainContainerClass = variant === 'body-bg-grey'
    ? { width: '100%', backgroundColor: '#ECF0F1', paddingTop: (ValidationMargin) ? '55px' : '0px' }
    : { width: '100%' }

  return (
    <>
      {!isEmpty(socialMediaTags) && <SocialMediaSeoTagsDynamic {...socialMediaTags} />}
      {!isEmpty(jsonLDData) && <JsonLDTagDynamic {...jsonLDData} />}
      <Head>
        <meta
          name="google-site-verification"
          content="4jwq_ezVBqmhtWhuE8z_8gVG9JUerRsCJH6sqLbswAI"
        />
        {isAppraisalForm ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        ) : (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
        )}
        {titleMeta && (
          <title>
            {f({ id: titleMeta })}
            {titleMetaComplementFormatted && (` | ${titleMetaComplementFormatted}`)}
          </title>
        )}

        {descriptionMeta && (
          <meta
            name="description"
            content={f({ id: descriptionMeta })}
          />
        )}

        <meta
          name="robots"
          content={isIndexedPage ? robotsContent : 'noindex, nofollow'}
        />

      </Head>
      <Box
        id="root-container"
        sx={{ ...sxProp }}
        className="PageContainer-root"
      >
        {(!hideHeader
          && !hideHeaderOnVTAFlow)
          && (
            <Header {...headerProps} />
          )}

        <Box
          id="main-content-container"
          sx={{ ...mainContainerClass }}
          className={clsx({
            isSchedule: isSchedulePath,
          })}
        >
          {children}
        </Box>

        {!hideFooter && <FooterDynamic {...footerProps} />}
        {/* {hideFooter && shortFooter && <AuctionFooterDynamic />} */}
        {router.asPath.includes('/subastas') && <AuctionFooterDynamic />}
        {(
          (variant === 'bg-grey')
          && !isDigitalExpedientPath)
          && (
            <AppraisalFooterDynamic
              displayPositionRelative={isInspectionSection}
              {...footerProps}
            />
          )}
      </Box>
    </>
  )
}

export default memo(Page)
