import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import sx from './styles'

const ModalCancelSell = (props) => {
  const { onClose: onCloseModal, onSubmit } = props
  return (
    <>
      <Typography
        id="modal-modal-description"
        variant="h2"
        sx={sx.title}
      >
        <FormattedMessage id="APPRAISAL_CANCEL_PROCESS_TITLE_QUESTION" />
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={sx.description}
      >
        <FormattedMessage id="APPRAISAL_CANCEL_PROCESS_DESCRIPTION" />
      </Typography>
      <Button
        id="btn-vta-continuarpasos"
        variant="contained"
        color="primary"
        sx={sx.button}
        onClick={onCloseModal}
      >
        <FormattedMessage
          id="APPRAISAL_CANCEL_PROCESS_BTN_NO"
        />
      </Button>
      <Button
        id="btn-vta-salirpasos"
        sx={sx.button}
        variant="outlined"
        color="primary"
        onClick={onSubmit}
      >
        <FormattedMessage
          id="APPRAISAL_CANCEL_PROCESS_BTN_YES"
        />
      </Button>
    </>

  )
}

export default ModalCancelSell
