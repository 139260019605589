import { useIntl } from 'react-intl'

export const isSellYourCarSteps = (path) => {
  const { formatMessage: f } = useIntl()
  const appraisalFormSteps = [
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_YEAR' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_MAKE' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_MODEL' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_VERSION' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_COLOR' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_KILOMETERS' }) },
    ),
    f(
      { id: 'ROUTE_QUOTE_CAR' },
      { step: f({ id: 'ROUTE_QUOTE_CAR_STEP_INFORMATION' }) },
    ),
  ]
  return appraisalFormSteps.indexOf(path) === -1
}
